<template>
    <div>
        <vs-card id="container" class="table-responsive vs-con-loading__container">
            <div slot="header" class="d-flex justify-content-between align-items-center">
                <h3 class="text-muted">{{title}}</h3>
                <div v-if="allowAddButton">
                    <vs-button @click="onNewClick" color="primary" type="border">Create New</vs-button>
                </div>
            </div>
            <div  class="">
                <vs-table ref="table" search stripe :data="displayData" v-model="selectedItem" @selected="onItemClick" :sst="true" :total="count" :max-items="step" @change-page="onPageChange" @search="handleSearch">
                    <!-- <template slot="header">
                        <h3>{{title}}</h3>
                    </template> -->
                    <template slot="thead">
                        <vs-th v-for="(item,index) in headers" :key="index" :sort-key="item.key">{{item.value}}</vs-th>
                    </template>
                    <template slot-scope="{data}">
                        <vs-tr v-for="(input,index) in data" :key="index" :data="input">
                            <vs-td v-for="(headerItem,innerIndex) in headers" :key="headerItem.key" :data="data[index][headerItem.key]">
                                <div v-if="innerIndex === 0" class="d-flex align-items-center">
                                    <div class="mr-2"><avatar :username="data[index][headerItem.key]"/></div>
                                    <div class="">
                                        <h5 class="m-b-0">{{data[index][headerItem.key]}}</h5>
                                    </div>
                                </div>
                                <p v-if="innerIndex > 0">{{data[index][headerItem.key]}}</p>
                            </vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
                <vs-pagination class="mt-4" :total="totalCount" v-model="currentPage" :max="step" @change="onPageChange"></vs-pagination>
            </div>
        </vs-card>
        <div class="absolute bg-dark w-100"></div>
    </div>
</template>

<script>
import Avatar from "vue-avatar";
import { mapState } from 'vuex';

export default {
    name: 'ItemList',
    props: ['title', 'headers', 'inputData', 'caller', 'allowAddButton'],
    components: { Avatar },
    data() {
        return {
            selectedItem: null,
            loadingMore: false,
            currentPage: 1,
            previousPage: 1,
            step: 5,
        }
    },
    computed: {
        ...mapState(['paymentLinksCount', 'transactionsCount', 'customersCount', 'settlementsCount']),
        totalCount() {
            window.console.log('Count Items:', this.count);
            return Math.floor(this.count / this.step) + ((this.count % this.step) > 0 ? 1 : 0);
        },
        displayData() {
            // let re = this.inputData.slice(this.step * (this.currentPage - 1), (this.step * (this.currentPage - 1) + this.step));
            // window.console.log("New Display:", re);
            return this.inputData.slice(this.step * (this.currentPage - 1), (this.step * (this.currentPage - 1) + this.step));
        },
        count() {
            switch(this.caller) {
                case 'paymentLinks':
                    return this.paymentLinksCount || 0;
                case 'transactions':
                    return this.transactionsCount || 0;
                case 'customers':
                    return this.customersCount || 0;
                case 'settlements':
                    return this.settlementsCount || 0;
                default: return 0;
            }
        }
    },
    methods: {
        onItemClick(item) {
            this.$emit('on-item-click', item);
        },
        onNewClick() {
            this.$emit('on-new-click');
        },
        onPageChange(page) {
            window.console.log("Page change:", page);
            if (page > this.previousPage && this.inputData.length < this.count) {
                window.console.log("Loading more...");
                this.loadingMore = true;
                this.$emit('load-more', page);
                this.showLoading();
            }
            this.previousPage = page;
        },
        handleSearch(searchString) {
            window.console.log("Searching...:", searchString);
        },
        showLoading() {
            this.$vs.loading({
                text: 'Loading more...',
                //type: 'point',
                container: '#container'
            });
        },
        closeLoading() {
            this.$vs.loading.close('#container > .con-vs-loading');
        }
    },
    watch: {
        inputData: function() {
            this.closeLoading();
        }
    }
}
</script>

<style scoped>
    .absolute {
        position: absolute;
        top: 0;
        left: 0;
    }
    .relative {
        position: relative;
    }
</style>